.Add-content-element-daialog{
    .centered{
        align-self: center;
        padding-top: 2.5em;
        .translation-select{
            position: relative;
            margin-left: auto;
            margin-right: auto;
        }
    }

    ul {
        .list-item{
            &.Mui-selected{
                background: gray;
                color: white;
                font-weight: bold;
            }
        }
    }
}