@import '../../../../../variables.scss';
#root {
  .main {
    #experiments {
      .button-panel {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
      }

      .experiment-list {
        margin-top: 1em;
        margin-bottom: 1em;
        .list-item {
          border-top: 1px dotted gray;
        }
      }

      .experiment-edit {
        margin: 2em 0;
        padding: 2em 0;
      }
    }

    // [data-question-id], [data-quiz-id]{
    //     position: relative;
    //     display: inline-flex;
    //     flex-direction: row;
    //     color: white;
    //     background: $questionColor;
    //     padding: 0.2em 0.5em;
    //     border-radius: 6px;
    //     border: 1px solid gray;
    //     &:before{
    //         color: white;
    //         content: "Q.ID:" attr(data-question-id);
    //         font-size: 10px;
    //         position: absolute;
    //         top: -8px;
    //         color: aqua;
    //         background: rgb(36, 72, 138);;
    //         padding: 0.1em 0.3em;
    //         border-radius: 6px;
    //         right: 4px;
    //     }
    //     &::after{
    //         content: "";
    //         display: inline-block;
    //         width: 30px;
    //         height: 20px;
    //         margin: 0 5px;
    //         border-bottom: 1px solid white;
    //     }
    // }

    // [data-quiz-id]{
    //     background-color: #067488;

    //     display: flex;

    //     &:before{
    //         content: "Q.ID:" attr(data-quiz-id);
    //     }
    //     &::after{
    //         content: "Quiz: " attr(data-quiz-title);
    //         width: auto;
    //         border-bottom: none;
    //     }
    // }
  }
}

.tox-dialog__body {
  span.quiz-question {
    font-size: 1.1em;
    color: #067488;
  }

  span.question-item {
    font-size: 1.3em;
    padding-left: 20px;

    &::before {
      color: pink;
      content: '✕';
    }
    &.right {
      color: #067488;
      &::before {
        color: green;
        content: '✓';
      }
    }
  }

  li.answer,
  .question-item {
    padding-left: 6px;
    color: #555;
  }
  li.answer.correct,
  .question-item.correct {
    color: rgb(0, 58, 13);
  }

  li.answer.correct::marker {
    color: green;
    content: '✓';
    margin-left: -10px;
  }

  li.answer.incorrect::marker {
    color: red;
    content: '✕';
  }

  //styles for image representation while creating quiz
  img.ans,
  img.que {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 5px;
  }
}
