.translation-desk{  
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 1em;  
  padding: 2em;
  margin: 0 0 1em;
  position: relative;

  .label{
    position: absolute;
    left: 1em;
    top: -1em;
    background: white;
    padding: 0.2em 2em;
    font-size: 1em;
    border-radius: 1em;    
    display: flex;
    align-items: center;
    div[role='group']{
      margin-left: 1em;
    }
  }

  .english{
    background-color: rgba(0,0,50,0.1);
  }

  .edit-display{
    background: white;
    padding: 1em;
    overflow-y: auto;
    max-height: 100%;
  }

  [data-ln1]::before {
    content: attr(data-ln1);
    position: sticky;
    top: 0;
    left: 0;
    background: black;
    color: white;
    width: 2em;
    height: 2em;
    display: flex;
    align-items:center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    opacity: 0.4;
    z-index: 10;
  }

  .english{

  }

  [data-ln1]{
      position: relative;
      padding-left: 2em;
  }


 
  .editor-before{
    &[data-ln1]::before {
      right: 0;
      left: inherit;
    }
  } 
}