.translation-select {
  position: absolute;
  right: 0;
  top: -20px;
  background: white;
  border-radius: 6px;
  padding: 0.2em;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.modal-window.full-screen{
  .translation-select{
    top: 0.4em;
    right: 3em;
  }
}