body #root .main {
  padding-bottom: 0px;
}

.users-list{
  // brreadcrumbs container
  .MuiBreadcrumbs-root{
    margin-bottom: 1em;
  }

  .add-user-button{
    background: green;
    color: white;
  }
}