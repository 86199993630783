@import "../../../variables.scss";

.modal-user-form{  
  

  .users-root-container {
  
    // .buttons-container {
    //   //background-color: yellowgreen;
    //   padding: 10px;
    //   display: flex;
    //   justify-content: flex-end;
    // }
  
    .image-fields-container {
      display: flex;
      //background-color: slateblue;
      padding: 10px;
  
      .image-container{
        align-items: flex-start;
        img{
          width: 100%;
          height: auto;
          flex-grow: 0;
        }
      }
      .text-fields {
        display: flex;
        flex-direction: column;
        //background-color: turquoise;
        padding: 10px;
      }
    }
  
    .modal-switch {
      display: flex;
      //background-color: violet;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  
    .image-container {
      display: flex;
      justify-content: center;
      //background-color: seagreen;
      padding: 10px;
    }
  
    .credentials-container {
      display: flex;
      padding: 10px;
      flex-direction: row;
      overflow-x: auto;
      max-width: 90vw;
  
      .MuiTableCell-root{
        border: none;
      }
    }
  }
}

