.navbar{    
    & > div {
        
        flex-grow: 1;
        align-items: center;
      justify-content: space-between;
    }

    .user-icon{
        display: flex;
        align-items: center;
        justify-content: stretch;
        cursor: pointer;
    }
    a{
        color: white;
        text-decoration: none;
    }
}