
@import "../../../variables.scss";
.course-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start ;
  justify-content: flex-start;
}

.no-courses{
  color: gray;
  font-size: 0.7em;
}

.course-select {
  max-height: 30vh;
  background: rgba(255,255,255,0.5);
  border-radius: 0.5em;
  overflow-y: scroll;
  li{
    cursor: pointer;
    &:hover{
      background: rgba(0,0,0,0.1);
    }
    font-size: 0.9em;
  }
}

.programs-assign-dialog{
  .modal-window{
    width: calc(70em - 2em);
    .modal-content{
      align-items: stretch;
    }
    .modal-container {
      display: flex;
      flex-direction: column;      
      flex: 1;
      .modal-buttons{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .modal-data {
        display: flex;
        flex-direction: column;
        margin: 0.5em 0;
    }
    
    .modal-transfer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    
  }

  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover  {
    background: $primary;
    color:  white;
  }
}


.search-box{
  background: white;
  border-radius: 0.5em;

  &::after, &::before{
    display: none;
  }
  input {
    padding: 0.5em 0.5em 0.5em 0;
  }
}

ul.image-list {
  display: grid; /* Enables grid layout */
  grid-template-columns: repeat(auto-fill, minmax(6em, 1fr)); /* Defines responsive columns */
  gap: 0.5em; /* Sets the gap between grid items */
  list-style: none; /* Removes default list bullets */
  padding: 0; /* Removes default padding */
  margin: 0 0 1em; /* Removes default margin */
  width: 100%;
  overflow-y: auto;
  max-height: 45vh; /* limit height and make it scrollable */

  li.selected .display {
    box-shadow: 0.2em 0.2em 0.5em $primary;
    border: 2px solid $primary;
  }
}