// Breakpoints
$bp_mobile: 320px;
$bp_mobileLandscape: 480px;
$bp_tablet: 768px;
$bp_tabletLandscape: 1024px;
$bp_desktop: 1200px;
$bp_desktopLarge: 1500px;
$bp_desktopWide: 1920px;

$headerHeight: 40px;
$footerHeight: 40px;
$sidebarWidth: 240px;

$mainTopMargin: 20px;

$borderRadius: 12px;

$bgLight: #edeffd;

$questionColor: #5472fc; // #dd55ff;

$primary: #3f51b5;
$dark50: rgba(0,0,0,0.5);


// simplified flex grid
$gridSpacing: 1em;


$colors: ( 
    white: white,
    red: red, 
    blue: blue, 
    green: green, 
    yellow: yellow, 
    orange: orange, 
    teal: teal, 
    darkgreen: darkgreen, 
    darkorange: darkorange,
    darkyellow: rgb(238, 227, 0)
);