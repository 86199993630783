@import "../../../variables.scss";

#root {

  .edit-org-root-container {
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: column;
    padding: 0px 30px;
    //background-color: rgb(218, 149, 22);
    .highlight{
      color: $primary;
    }
    .item{
      display: flex;    
      justify-content: space-around;
      align-items: baseline;
      flex-grow: 1;
      margin: 0 1em 1em 0;
      &:last-child{
        margin-right: 0;
      }
    }
  
    .breadcrumbs-container{
      display: flex;
      padding: 5px;
    }
  
    .users-container {
      // display: flex;    
      // justify-content: space-around;
      // align-items: center;
      // padding: 10px;
    }
  
    .programs-container {
      // display: flex;    
      // justify-content: space-around;
      // align-items: baseline;
    }
  
    .information-container {
      
  
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 7px;
      }
    }
  }
}
