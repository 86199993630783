@import "../../../variables.scss";

.sidebar{
    z-index: 1;
    
   >div { 
        width: $sidebarWidth;
        padding-top: $headerHeight;
        // top: $headerHeight;
        // height: calc(100% - #{$headerHeight + $footerHeight})    ;        

        .drawer-menu-item{
            &.active{
                background-color: rgba(0,0,0,0.1);
            }
        }
    }
}