.translations-component{
  .centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    .Translation-header{
      margin-bottom: 1em !important ;
    }
    .translation-select{
        position: relative;
    }
  }
  .right-corner{
    display: flex;
    justify-content: flex-end;
    .translation-select{
      position: relative;
    }
  }
}
.modal-window.full-screen{
  max-height: 100vh;

  h2 {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .course, .item-course-experiment {
    max-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .course-content, .tab-content{
      flex-grow: 1;
  
      display: flex;
      flex-direction: column;
      align-items: stretch;
      max-height: calc(100% - 2em);
      padding: 1em 0;
      overflow-y: auto;
      
      .edit-display{
        max-height: 370px;
        margin-top: 7.2em;
        img {
          max-width: 100%;
        }
      }
    }
  }

  .close-button{
    width: 3em;
    height: 3em;
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    padding: 0;
    min-width: auto;
  }

  .item-course-experiment{
    .experiment-tabs{
      a{
        font-size: 0.7em;
      }
    }
  }
}