.wrapper-tabs{
    text-align: center;
    display: flex!important;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    h1{
        font-size: 3em;
        margin-top: 1em;
        margin-bottom: 0.3em;
    }
}
#login,#register{
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    flex-grow: 1;    

    .login-form{
        position: relative;
        flex-grow: 0;
        text-align: center;
        padding: 2em;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .spin-over{
            position: absolute;
            top: 29px;
            left: calc(50% - 20px);            
        }
    }
}

#login{
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    > div {
        flex-grow: 1;
    }

    .loader {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        align-items: center;
        height: 270px;
        justify-content: center;
    }
}