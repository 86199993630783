@import "../../../../variables.scss";
#root{

    .content {
        display: flex;
        .grid-item{
            
            &.left-panel {
                flex-basis: calc(20% - #{2 * $gridSpacing });
            }
            
            &.right-panel{
                flex-basis: calc(80% - #{2 * $gridSpacing });
            }
        
            &.full-width{
                flex-basis: calc(100% - #{2 * $gridSpacing });
            }
        }
        
    
        .category-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    
        span.badge{
            position: absolute;
            right: 0;
            top: 0em;
            background: red;
            color: white;
            padding: 0;
            border-radius: 1em;
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
        }
    
        .categories-list, .category-view {
            background: white;
            padding: 0.5em;
            border-radius: 0.5em;
            flex-grow: 1;
    
            .title {
                font-size: 1.3em;
                font-weight: bold;
                color: $primary;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
    
    
            li{
                display: flex;    
    
                > a{
                    margin: 0.2em 0;
                    border-radius: 0.5em;
                    padding: 0.5em;
                    background: rgba(0,0,0,0.1);
                }
            }
    
            
        }

        .categories-list{
            > h3{
                margin: 0;
                padding: 0;
                font-size: 0.9em;
                color: $primary;
            }
        }
    
        .page-list{
            padding: 0.4em;
            background: lightgray;
            border-radius: 0.5em;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h3{
                margin: 0 0 0.2em;
                padding: 0;
                font-size: 0.9em;
                color: $primary;
            }
            .page-icon{
                // background: rgba(0,0,0,0.1);
                background: white;
                margin: 0 0.2em;
                padding: 0.2em 0.5em;
                border-radius: 0.5em;
                display: flex;
                width: auto;
                // flex-direction: column;
                align-items: center;
                justify-content: center;
                &.add-button{
                    background: green;
                    color: white;
                    font-size: 0.9em;
                }
                .page-number{
                    font-size: 0.9em;
                    font-weight: bold;
                }
                .page-id{
                    color: rgba(0,0,0,0.3);
                    display: none;
                }
    
                &.active{
                    background: gray;
                    color: white;
                }
            }
        }
    
        .page-header{
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.5em;
            border-bottom: 3px dotted rgba(0,0,0,0.2);
            margin-bottom: 0.5em;
            .title{
                .page-index{
                    font-size: 0.9em;
                    color: $primary;
                }
                .page-id{
                    color: rgba(0,0,0,0.3);
                }
            }
        }
    
        .page-content {
            padding: 0.2em;
            margin: 0.2em 0;
            border: 2px dotted lightgray;
            border-radius: 0.5em;
    
            .page-content-header{
                > span {
                    color: lightgray;
                }
            }
    
            .MuiContainer-root {
                padding: 0; // remove padding from container so that it aligns with interface elements
            }
        }
    }
}