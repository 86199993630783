@import './variables.scss';

html,
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  flex-grow: 1;
  background-color: $bgLight;

  $sides: top, right, bottom, left;

  @each $var in $sides {
    @for $offset from 0 to 21  {      
      .m-#{$var}-#{$offset}{
        margin-#{$var}: #{$offset * 0.1 }em;
      }  
      .p-#{$var}-#{$offset}{
        padding-#{$var}: #{$offset * 0.1 }em;
      }  
    }

    @for $thickness from 0 to 12 {
      .b-#{$var}-#{$thickness}{
        border-#{$var}: solid #{$thickness}px;
      }
    }
  }

  @for $offset from 0 to 21  {      
    .m-y-#{$offset}{
      margin-top: #{$offset * 0.1 }em;
      margin-bottom: #{$offset * 0.1 }em;
    }  
    .m-x-#{$offset}{
      margin-left: #{$offset * 0.1 }em;
      margin-right: #{$offset * 0.1 }em;
    }  
    .p-y-#{$offset}{
      padding-top: #{$offset * 0.1 }em;
      padding-bottom: #{$offset * 0.1 }em;
    }  
    .p-x-#{$offset}{
      padding-left: #{$offset * 0.1 }em;
      padding-right: #{$offset * 0.1 }em;
    }  
  }


$flexItemsMethods: baseline, flex-start, flex-end, stretch, center;
$flexContentMethods: baseline, flex-start, flex-end, stretch, center, space-around, space-evenly, space-between;

.flex {
  display: flex;
  

  &.grid-container{
    margin : - $gridSpacing;
    flex-wrap: wrap;
    flex-grow: 1;

    .grid-item{
      margin: $gridSpacing;
    }
  }

  &.column{
    flex-direction: column;
  }
  &.row{
    flex-direction: row;
  }

  @each $var in $flexItemsMethods {
    &.align-items-#{$var}{
      align-items: $var;
    }  
  }

  @each $var in $flexItemsMethods {
    &.align-self-#{$var}{
      align-self: $var;
    }  
  }

  @each $var in $flexContentMethods  {
    &.justify-content-#{$var}{
      justify-content: $var;
    }
  }
}

.grow-0{
  flex-grow: 0;
}

.grow-1{
  flex-grow: 1;
}

.grow-2{
  flex-grow: 1;
}


.dark50{
  color: rgba(0,0,0,0.5);
}


// MENU BLOCK (dashboard and such)
ul.menu-block{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -1em;

  & > li{
      display: flex;
      flex-grow: 1;	
      min-height: 10em;
      flex-direction: column;
      padding: 1em;
      background-color: $primary;
      margin: 1em;
      border-radius: 0.5em;
      color: white;
      a{
          color: white;
          text-decoration: none;
          flex-grow: 1;
      }
      & > a {
          color: #64c8ff;
          font-size: 2.4em;
          font-weight: 300;
          transition: 0.5s all;

          &:hover{
            color: white;
          }
      }

      
      
      ul {
          padding: 0;
          list-style: none;        
          flex-grow: 1;
              > li{                        
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                  padding-left: 0.5em;
                  a{                            
                      padding: 1em;
                      color: white;
                      text-decoration: none;
                      flex-grow: 1;
    
                      &:hover{
                          background-color: rgba(255,255,255,0.1);
                      }
                  }
              }        
      }
  }
}


  #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    @for $var from 1 to 6 {
      h#{$var} {
        font-size: #{2 - ($var - 1) * 0.2}em;
        // margin-top: #{1.6 - ($var - 1) * 0.2 }em;
        margin-top: 1em;
        margin-bottom: 0.4em;
      }
    }

    @each $name, $color in $colors {
      .bg-#{$name} {
        background-color: $color;
      }
      .color-#{$name}{
        color: $color;
      }
    }

    .bold {
      font-weight: 600;
    }

    .navbar {
      // background: #5c7372;
      height: $headerHeight;
      padding: 0 2em;
      color: white;
      .menu-button {
        cursor: pointer;
      }      
    }

    .main {
      flex-grow: 1;
      transition: 255ms padding-left;
      padding-top: #{$headerHeight + $mainTopMargin};
      padding-bottom: #{$footerHeight + $mainTopMargin};
      &.drawer-open {
        padding-left: $sidebarWidth;
      }

      .page{
        margin: 0 2em;
      }
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: $footerHeight;
      align-items: center;
      width: 100%;
      justify-content: center;
      color: white;
      z-index: 1;
      // background: #7ea09f;
    }

    .messaging{
      .notifications-container{
        position: fixed;
      }
    }
  }

  // styling modal-window
  .modal-window{
    .modal-header{
      color: $primary;
      margin: 0em 0 0.5em;
    }
    
    &.confirm-dialog{     
        width:auto;      
    }
  }


  .auto-breadcrumbs {
    li {
      a, p {
        display: flex;
        align-items: center;
      }
      a{
        text-decoration: none;
        color: $primary;
      }
    }
  }
}



.icon-green{
  &, &.svg{
    color: green;
  }
}


