.organization-form{
  .fields{
    display: flex;
    flex-direction: column;
  }

  .buttons{
    display: flex;
    justify-content: space-between;
  }
}