.editor-button-bar {
}

.tox-dialog__body-content {
  .tox-form {
    img {
      max-height: 40px;
      max-width: 40px;
    }
  }

  .info {
    background: #baffff;
    padding: 0.2em;
    font-size: 0.87em;
    color: #007171;
    strong{
      font-weight: bold;
      color: rgb(5, 43, 54);
    }
  }
}
