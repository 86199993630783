// .modal-container {
//   flex-direction: column;
//   display: flex;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   min-width: 65%;
//   width: 65% !important;
//   min-height: 85vh;
//   background-color: rgb(204, 196, 196);
//   border-radius: 8px;
//   overflow: hidden;
// }

// .modal-header {
//   min-height: 7.5vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal-data {
//   flex-direction: column;
//   display: flex;
//   min-height: 15vh;
//   padding: 7px;
// }

// .modal-transfer {
//   display: flex;

//   min-height: 60vh;
//   align-content: center;
// }

// .modal-buttons {
//   min-height: 7.5vh;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   padding: 7px;
// }

.programs-assign-dialog{
    .centered{
        align-self: center;
        padding-top: 2.5em;
        .translation-select{
            position: relative;
            margin-left: auto;
            margin-right: auto;
        }
    }
}