$color1: #ac88df;
$color2: rgb(180, 74, 255);


@keyframes disappear{
  0%{
    opacity: 1;
    transform: rotateX(90deg);
  }
  50%{
    opacity: 0.5;
    transform: rotateX(0deg);
  }
  100%{
    display: none;
    opacity: 0;
    transform: rotateX(90deg);
  }
}


.questions-editor {
  

  .quiz-editor{
    position: relative;

    &::before{      
      font-weight: bold;
      position: sticky;
      right: 0;
      top: 40px;
      font-size: 0.7em;
      z-index: 20;
      opacity: 1;
    }
    &.changed::before{
      content: "Changed!";
      color: red;
      opacity: 1;      
    }

    &.saved::before{
      content: "Saved";
      color: green;
      animation: disappear 2s;
      animation-fill-mode: forwards;
      
    }

    &.changed::before{
      content: "Changed!";
      color: $color2;
      font-weight: bold;
      position: sticky;
      right: 0;
      top: 0;
    }
  }
  
  .quiz {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 2em;
  }

  

    .answer-text-rendered, .field-text-rendered{
      font-size: 0.8em;
      color: $color2;
      // padding: 0.4em 1em;

      .nom{
        padding-bottom: 0.3em;
      }
    }

    .question-fields, .question-answers {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }


    margin-top: 1em;
    .question-element{

      position: relative;
      margin: 1em 0 2em;
      min-height: 2em;
      padding-left: 2.2em;
      &:last-child{
        margin-bottom: 0;
      }

      &.edit{
        margin-bottom: 3em;
      }

      &::before {
        content: attr(data-index);
        position: absolute;
        left: 0;
        top: 0;
        width: 1em;
        height: 100%;
        border-radius: 0.5em;
        background: $color1;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6em;
      }
      &.answer{
        &::before{
          background-color: $color2;
          font-size: 0.7em;
          width: 2em;
        }
        input:disabled{
          color: black;
        }
      } 

      &.quiz{

        padding-left: inherit;
        &::before{
          display: none;
        }
      } 

    }
  
}