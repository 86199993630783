.transfer-list{

  align-items: flex-start!important;

  .transfer-list-header {
    display: flex;
    font-size: 1.2em;
    line-height: 1.2em;
    min-height: 1.4em;
    padding: 0.4em 0.2em;
    justify-content: center;
  }

  > div {
    // left, right and buttons blocks
    flex-grow: 1;
    &.tl-left, &.tl-right{
      width: 35%;
      > div {
        max-height: 400px;
      }
    }
  }

  .horizontal-separator{
    display: block;
    
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(201, 200, 200);
    width: 100%;
  }
}