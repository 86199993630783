.question-item {

  span.ordering {
    display: inline-flex;
    margin: 0 0.5em 0 1em;
    font-size: 0.6em;
    font-weight: bold;
    background-color: gray;
    width: 2em;
    height: 2em;
    border-radius: 1em;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    
    &::before{
      content: "order";
      position: absolute;
      color: gray;
      top: -1.2em;
      font-size: 0.7em;
    }
  }
  
} 